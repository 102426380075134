var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "el-form",
            {
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "160px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "用户信息" } },
                [
                  _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属角色" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择角色名称/编码",
                                    multiple: "",
                                    filterable: ""
                                  },
                                  model: {
                                    value: _vm.appForm.roleCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appForm, "roleCode", $$v)
                                    },
                                    expression: "appForm.roleCode"
                                  }
                                },
                                _vm._l(_vm.roleList, function(item) {
                                  return _c("el-option", {
                                    key: item.roleCode,
                                    attrs: {
                                      label:
                                        item.roleName + "（" + item.code + "）",
                                      value: item.roleCode
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "所属角色" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.roleFormat(_vm.appForm.roleName)
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name", label: "姓名" } },
                        [
                          _c(
                            "ics-item-inner",
                            {
                              attrs: {
                                prop: _vm.appForm.name,
                                format: _vm.utils.isEffectiveCommon
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入姓名" },
                                model: {
                                  value: _vm.appForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "name", $$v)
                                  },
                                  expression: "appForm.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$route.params.editMode === "add" && _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "mobile", label: "手机号码" } },
                            [
                              _c(
                                "ics-item-inner",
                                {
                                  attrs: {
                                    prop: _vm.appForm.mobile,
                                    format: _vm.utils.isEffectiveCommon
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.disabledInfo,
                                      placeholder: "请输入手机号码"
                                    },
                                    model: {
                                      value: _vm.appForm.mobile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.appForm, "mobile", $$v)
                                      },
                                      expression: "appForm.mobile"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$route.params.editMode !== "add"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "手机号码" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.appForm.mobile
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.$route.params.editMode === "add"
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "email", label: "个人邮箱" } },
                            [
                              _c(
                                "ics-item-inner",
                                {
                                  attrs: {
                                    prop: _vm.appForm.email,
                                    format: _vm.utils.isEffectiveCommon
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.disabledInfo,
                                      placeholder: "请输入个人邮箱"
                                    },
                                    model: {
                                      value: _vm.appForm.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.appForm, "email", $$v)
                                      },
                                      expression: "appForm.email"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.$route.params.editMode !== "add"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "个人邮箱" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(_vm.appForm.email)
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$route.params.editMode === "add"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "idCardNo", label: "身份证号码" }
                            },
                            [
                              _c(
                                "ics-item-inner",
                                {
                                  attrs: {
                                    prop: _vm.appForm.idCardNo,
                                    format: _vm.utils.isEffectiveCommon
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入身份证号码" },
                                    model: {
                                      value: _vm.appForm.idCardNo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.appForm, "idCardNo", $$v)
                                      },
                                      expression: "appForm.idCardNo"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$route.params.editMode !== "add"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "身份证号码" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      _vm.appForm.idCardNo
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.viewEdit()
                    ? _c("ics-button-inner", {
                        attrs: {
                          loading: _vm.loading.submit,
                          "cancel-title": "取消",
                          "submit-title": "提交"
                        },
                        on: { submit: _vm.submitForms }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }